import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {layoutOptions} from "../components/Content/RichText";

import Layout from "../components/layout";
import SEO from "../components/seo";

const TermsAndConditions = ({data, location }) => {
    const content = data.contentfulStaticRichTextPage;
    const richText = documentToReactComponents(data.contentfulStaticRichTextPage.content.json, layoutOptions);

    return (
        <Layout page={location.pathname} props={content}>
            <SEO title={content.pageTitle} />
            <Helmet>
            </Helmet>
            <section className="uk-section uk-section-large">
                <div className="uk-container uk-container-xsmall uk-margin-large">
                {richText}
                </div>
            </section>
        </Layout>
    )
}

export default TermsAndConditions;

export const termsAndConditionsQuery = graphql`
    query termsAndConditionsQuery($slug: String) {
        contentfulStaticRichTextPage(slug: {
            eq: $slug
        }) {
            slug
            pageTitle
            description
            content {
                json
            }
        }
    }`
